import simpleParallax from "simple-parallax-js";
// import './jquery.easeScroll';
// import './jquery-inertiaScroll';
// import './jquery.nicescroll';
import Rellax from './rellax';
// import Rellax from 'rellax'
import luxy from 'luxy.js'
// import jquery from 'jquery'
import { state } from './_variables'


let headerHeight = 0;
let scrollTop = 0;
let scrollHeight = 0;
let setTimeoutId = null;
const isDisplayNoneIds = ["page_category_top", "page_product_list"]
const bodyId = $('body').attr('id');

/**
 * スクロール位置リセット処理
 */
history.scrollRestoration = "manual"

const created = () => {
    
    const ua = navigator.userAgent.toLowerCase();
    const isiOS = (ua.indexOf('iphone') > -1) || (ua.indexOf('ipad') > -1);
    if (isiOS) {
        editViewPortForiOS();
    }

    $(".hoverable").mouseenter(function() {
        $(this).find("span").addClass("hover")
        $(this).find(".svg-container svg:last-of-type path").attr("fill", "#393a3a")
    })
    $(".hoverable").mouseleave(function() {
        $(this).find(".svg-container svg:last-of-type path").attr("fill", "#C4C1C1")
    })
    $(".hoverable span").on('transitionend', function(e) {
       if(e.target.localName !== 'path') $(this).removeClass('hover')
    })

};

const mounted = () => {
    // alert(localStorage.getItem('currentPage'))
    const regexp = /^(?!.*\/products\/detail).*(?=\/products).*$/
    if (!regexp.test(location.pathname)) {
        localStorage.setItem('currentPage', location.pathname)
        const searchParams = new URLSearchParams(window.location.search);
        const localStorageHistory = searchParams.has('history') ? searchParams.get('history') : null
        localStorage.setItem('history', localStorageHistory)
    }
    /**
     * パララックス処理
     */
     new Rellax('.rellax', {
        speed: -3,
        center: true,
        breakpoints: [576, state.breakpoints.sm, 1201]
    });
    /**
     * スクロールアニメーション発火
     */
     const animations = document.querySelectorAll(".js-scroll");
     const animations2 = document.querySelectorAll(".js-scroll2");
     const animations3 = document.querySelectorAll(".js-scroll3");
     const animationsProduct = document.querySelectorAll(".js-scroll-product");
     const options = {
         root: null,
         rootMargin: "-90% 0px -10%",
         threshold: 0,
     };
     const options2 = {
         root: null,
         rootMargin: "-10% 0px -90%",
         threshold: 0,
     };
     const options3 = {
         root: null,
         rootMargin: "-50% 0px -50%",
         threshold: 0,
     }
     const optionsProduct = {
         root: null,
         rootMargin: isSp() ? '-90% 0px -10%' : "-50% 0px -50%",
         threshold: 0,
     }
     const observer = new IntersectionObserver(callback, options);
     const observer2 = new IntersectionObserver(callback, options2);
     const observer3 = new IntersectionObserver(callback, options3);
     const observerProduct = new IntersectionObserver(callback, optionsProduct);
 
     /**
      * アニメーションの追加処理
      */
     function addAnimation(e) {
         const classAdd = e.dataset.animation
         if (classAdd) {
             e.classList.add(classAdd)
         }
     }
     animations.forEach(e => {
         addAnimation(e)
         observer.observe(e);
     })
     animations2.forEach(e => {
         addAnimation(e)
         observer2.observe(e);
     })
     animations3.forEach(e => {
         addAnimation(e)
         observer3.observe(e);
     })
     animationsProduct.forEach(e => {
         addAnimation(e)
         observerProduct.observe(e);
     })
 
     function callback(entries) {
         entries.forEach((entry, i) => {
             let toggleTogetherId;
             let position = 'top'
             if (entry.target.dataset.toggleTogether) {
                 toggleTogetherId = entry.target.dataset.toggleTogether
             }
             if (entry.target.dataset.position) {
                 position = entry.target.dataset.position
             }
             if (entry.isIntersecting && position === 'top' && entry.boundingClientRect.top > 0 || !entry.isIntersecting && position === 'bottom' && entry.boundingClientRect.top < 0) {
                 if (!entry.target.classList.contains('is-animation')) entry.target.classList.add('is-animation')
                 if (!entry.target.classList.contains('toggle-animation')) entry.target.classList.add('toggle-animation')
                 if (toggleTogetherId) {
                     if (!document.getElementById(toggleTogetherId).classList.contains('toggle-animation'))
                         document.getElementById(toggleTogetherId).classList.add('toggle-animation')
                 }
             } else if (!entry.isIntersecting && position === 'top' && entry.boundingClientRect.top > 0 || entry.isIntersecting && position === 'bottom' && entry.boundingClientRect.top < 0) {
                 if (entry.target.classList.contains('toggle-animation')) entry.target.classList.remove('toggle-animation')
                 if (toggleTogetherId) {
                     if (document.getElementById(toggleTogetherId).classList.contains('toggle-animation'))
                         document.getElementById(toggleTogetherId).classList.remove('toggle-animation')
                 }
             }
         })
     }
    /**
     * 慣性スクロール
     */
    if (!isSp()) {
        luxy.init({
            wrapper: '#inertia',
            wrapperSpeed: 0.1
        });
    }
    headerHeight = $(".ec-layoutRole__headerWrapper").outerHeight();
    scrollTop = $(window).scrollTop();
    scrollHeight = document.documentElement.scrollHeight
    if ($(".parallax-window").length) {
        // 画像parallax
        $(".parallax-window").parallax();
    }

    // テキストparallax
    const parallax = document.querySelectorAll("[data-simple-parallax]");
    for (let i = 0; i < parallax.length; i++) {
        attachParallax(parallax[i]);
    }

    // 視差効果処理
    // const tiltBox = document.querySelector('.tilt-box')
    // VanillaTilt.init(tiltBox, {
    //     scale: 1.1,
    //     max: 3,
    //     speed: 600
    // })
    // tiltBox.addEventListener("tiltChange", function(e) {
    //     const css = e.target.getAttribute('style')
    //     const id = e.target.dataset.id
    //     if (id) {
    //         const idEle = document.getElementById(id)
    //         idEle.setAttribute('style', css)
    //     }
    // })
    // tiltBox.addEventListener("mouseleave", function(e) {
    //     setTimeout(() => {
    //         const css = e.target.getAttribute('style')
    //         const id = e.target.dataset.id
    //         if (id) {
    //             const idEle = document.getElementById(id)
    //             idEle.setAttribute('style', css)
    //         }
    //     },100)
    // })
    let startMouseMove = false;
    /**
     * 読み込み時にanimationに必要なcssを付与
     */
    $('.tilt-box').each(function() {
        const target = $(this).data('target')
        if (target) {
            $(target).css({
                'position': 'relative',
                'left': '0',
                'top': '0',
                'transform': 'scale(1)',
                'transition': 'transform 5s cubic-bezier(0,.9,.2,1), top 1s ease, left 1s ease'
            })
        }
    })
    $('.tilt-box').on('mousemove', function(e) {
        let moveRange = 2.8;
        const dataMoveRange = e.target.dataset.moveRange
        if (dataMoveRange) {
            moveRange = Number(dataMoveRange)
        }

        const offset = {
            x: e.offsetX,
            y: e.offsetY
        }
        const contentSize = { width: $('.tilt-box').width(), height: $('.tilt-box').height() }
        let hoverPositionAmountX = Math.round(offset.x / contentSize.width * 100);
        let hoverPositionAmountY = Math.round(offset.y / contentSize.height * 100);
        if (hoverPositionAmountX < 0) {
            hoverPositionAmountX = 0;
        } else if (hoverPositionAmountX > 100) {
            hoverPositionAmountX = 100;
        }
        if (hoverPositionAmountY < 0) {
            hoverPositionAmountY = 0;
        } else if (hoverPositionAmountY > 100) {
            hoverPositionAmountY = 100;
        }

        const cmoveX = (((moveRange * 2) * (hoverPositionAmountX / 100)) - moveRange) * -1;
        const cmoveY = (((moveRange * 2) * (hoverPositionAmountY / 100)) - moveRange) * -1;

        const target = e.target.dataset.target
        if (target) {
            // const idEle = document.getElementById(id)
            // idEle.setAttribute('style', css)
            // 初回だけアニメーション
            if (!startMouseMove) {
                // ここを対応するIDに変更
                // $(target).animate({
                //     'position': 'relative',
                //     'left': cmoveX + 'rem',
                //     'top': cmoveY + 'rem',
                //     'transform': 'scale(1.1)'
                // })
            }
            startMouseMove = true;
            // ここを対応するIDに変更
            $(target).css({
                // 'position': 'relative',
                // 'left': cmoveX + 'rem',
                // 'top': cmoveY + 'rem',
                'transform': 'scale(1.15)',
                'transition': 'transform 3.5s cubic-bezier(0,.9,.2,1)'
            })
        }

    }).on('mouseleave', function(e) {
        // ここを対応するIDに変更
        const target = e.target.dataset.target
        $(target).css({
            // 'position': 'relative',
            // 'left': '0',
            // 'top': '0',
            'transform': 'scale(1)',
            'transition': 'transform 0.5s ease'
        })
        startMouseMove = false;
    });



    // if (window.location.pathname.indexOf("/products") != 0){
    //     }


    // $("html").easeScroll({
    //     frameRate: 64,
    //     animationTime: 2000,
    //     stepSize: 30,
    //     pulseScale: 8,
    //     accelerationDelta: 0.01,
    //     accelerationMax: 0.07,
    //     touchpadSupport: true,
    //     fixedBackground: true,
    // });

    // $("#header .ec-layoutRole__contents .ec-layoutRole__footer").inertiaScroll({
    //     parent: $(".ec-layoutRole"),
    // });

    // $('body').niceScroll({
    //     scrollspeed: 40,
    //     mousescrollstep: 40,
    //     autohidemode: false,
    //     zindex: 100000000000000,
    //     cursorcolor: '#007db9',
    //     cursorwidth: 0,
    //     cursorborder: 0,
    //     cursorborderradius: 0,
    //     cursorminheight: 10,
    //     touchbehavior: false,
    //   });



};

const resize = () => {
    headerHeight = $(".ec-layoutRole__headerWrapper").outerHeight();
    setFillHeight()
    
    var fontWidth = $(window).width()/144

    if( $(window).width() < state.breakpoints.sm ){
        fontWidth = $(window).width()/37.5
    }

    $('html').css("font-size", fontWidth + "px")
};

const scroll = () => {
    // スクロール間引き処理
    if (setTimeoutId) return;

    if (isSp()) {
        handleBackScrollSpNav();
    } else {
        handleBackScrollPcNav();
    }
    // setTimeoutId = setTimeout(() => {

    //     setTimeoutId = null;
    // }, 100);
};

const handleBackScrollPcNav = () => {
    const $backScrollnav = $("#ec-backscrollNav");
    if (headerHeight + 200 > $(window).scrollTop()) {
        $backScrollnav.removeClass("show");
        scrollTop = $(window).scrollTop();
        return;
    }
    if (scrollTop < $(window).scrollTop()) {
        $backScrollnav.removeClass("show");
    } else {
        if (isDisplayNoneIds.filter(id => { return id == bodyId }).length > 0) return;
        $backScrollnav.addClass("show");
    }
    scrollTop = $(window).scrollTop();
};

const handleBackScrollSpNav = () => {
    // -----------------------------------------------------
    // L:60-66
    // ヘッダー下に写真があるページのヘッダー下線 打ち消しクラス付与処理
    // -----------------------------------------------------
    if ($(window).scrollTop() <= 50) {
        $(".ec-layoutRole__headerWrapper").addClass("top");
        // scrollTop = $(window).scrollTop();
        // return;
    } else {
        $(".ec-layoutRole__headerWrapper").removeClass("top");
    }

    if (headerHeight > $(window).scrollTop()) {
        $(".ec-layoutRole__headerWrapper").addClass("show");
        scrollTop = $(window).scrollTop();
        return;
    }
    if (scrollTop < $(window).scrollTop()) {
        $(".ec-layoutRole__headerWrapper").removeClass("show");
    } else {
        $(".ec-layoutRole__headerWrapper").addClass("show");
    }

    const scroll = $(window).scrollTop() + $(window).outerHeight();

    if (scrollHeight - 50 > scroll) {
        scrollTop = $(window).scrollTop();
    }
};
const setFillHeight = () => {
    const vh = window.innerHeight * 0.01;
    document.documentElement.style.setProperty('--vh', `${vh}px`);
}

const isSp = () => {
    return $("body").outerWidth() < state.breakpoints.sm;
};

const attachParallax = (el) => {
    const options = {
        overflow: el.dataset.overflow ? el.dataset.overflow === "true" : false,
        scale: el.dataset.scale ? el.dataset.scale : 1.2,
        orientation: el.dataset.orientation ? el.dataset.orientation : "up",
        delay: el.dataset.delay ? el.dataset.delay : 0.6,
    };
    new simpleParallax(el, options);
};

const editViewPortForiOS = () => {
    var viewport = document.querySelector('meta[name="viewport"]');
    if (viewport) {
        var viewportContent = viewport.getAttribute('content');
        viewport.setAttribute('content', viewportContent + ', user-scalable=no');
    }
}

document.addEventListener("DOMContentLoaded", () => created());
window.addEventListener("load", () => mounted(), { passive: true });
window.addEventListener("scroll", () => scroll(), { passive: true });
window.addEventListener("resize", () => resize(), { passive: true });
$(function() {
    // handleBackScrollSpNav();
    setFillHeight()
});